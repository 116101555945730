import React,{useState,useRef,useEffect} from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import Layout from '../components/layout'
import moment from "moment";
import "../style/calendar.css";
import {BsChevronCompactLeft,BsChevronCompactRight,BsPlus,BsDash} from "react-icons/bs"
import Button from '../components/button'
import useClickOutside from '../hooks/useClickOutside'
import axios from 'axios'
import { navigate } from "gatsby"
const apiBase = 'https://ryzen-api.com';
// const apiBase = 'http://127.0.0.1:8001'

const PleaseSelectAnotherDay = '請點擊日曆選擇日期'

const OptionsRenderer = ({value,options,onOptionClick=()=>{},onOptionsOpen=()=>{},onOptionsClose=()=>{}}) =>{
  return (
    <div className='absolute bg-white shadow-md border-b border-gray-100 border-t text-gray-99 top-full left-0 w-full z-10'>
      {
        options.map((opt,idx)=>{
          if(value === opt) return null;
         return (
           <div key={`optidx-${idx}`} className='px-2 cursor-pointer py-4 flex justify-end hover:bg-gray-99 hover:text-white transform duration-300 md:py-1' onClick={()=>{onOptionClick(opt)} }>
             {opt}
           </div>
         )
        })
      }
    </div>
  )
}

export default () => {

  // const timeOptions = ['10:00','14:00','16:00'];
  // const MaxGuest = 5;

  const dateOffset = 7;
  const hourOffset = 4;

  const [timeOptions,setTimeOptions] = useState([]);
  const [maxGuest,setMaxGuest] = useState(1);

  const [availableTimeOptions,setAvailableTimeOptions] = useState([]);
  // const [maxBookingEachPeriodDefault,setMaxBookingEachPeriodDefault] = useState(null);

  const [loadingCalendar,setLoadingCalendar] = useState(true);

  const [maxBookingEachPeriod,setMaxBookingEachPeriod] = useState(null);
  const [bookings,setBookings] = useState(null);
  const [GuestOptions,setGuestOptions] = useState([1]);

  const [bookingStatus,setBookingStatus] = useState(0);

  const [bookingDate,setBookingDate] = useState(new Date());
  const [bookingTime,setBookingTime] = useState(null);
  const [bookingGuest,setBookingGuest] = useState(1);
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [timeOptionsOpen,setTimeOptionsOpen] = useState(false);
  const [guestOptionOpen,setGuestOptionOpen] = useState(false);


  const submitRef = useRef(null)

  const timeSelector = useRef(null)
  useClickOutside(timeSelector,()=>{
    setTimeOptionsOpen(false)
  });

  const guestSelector = useRef(null)
  useClickOutside(guestSelector,()=>{
    setGuestOptionOpen(false)
  });
  // const timeSelector = useRef(null)
  // useClickOutside(timeSelector, onClickTimeSelectorOutside);


  const shortWeekdayFormatter = (locale, date) => {
    const dayInChinese = ["日","一","二","三","四","五","六"]
    const day = date.getDay()
    return dayInChinese[day]
  }
  const monthYearFomatter = (locale, date) =>{
    const Month = date.getMonth()
    const Year = date.getFullYear()
    // console.log(Month,Year)
    return moment(date).format('M月 YYYY');
    // return (Month + 1) + '月' +' ' + Year
  }

  const setStartDate = () => {
    let today = new Date()
    today.setDate(today.getDate() - 15)
    return today
  }

  const setMaxDate = () => {
    let today = new Date()
    today.setDate(today.getDate() + 15)
    return today
  }

  const addBooking = () =>{

    const bookingDay = moment(bookingDate).format('YYYY-MM-DD')
    const postData = {
      name:name,
      phone:phone,
      email:email,
      people:bookingGuest,
      booking_time:bookingDay,
      time_period:bookingTime,
      // userId:null,
    }

    axios.post(`${apiBase}/api/guestBooking`,postData).then(
      (response)=>{
        console.log(response)
        navigate(
          "/booking-result/",
          {
            replace: false,
            state: {
              result:response.data
            },
          }
        )
      },
      (error)=>{
        console.log(error)
      }
    )
  }

  // 日期是否可用
  const isDisabledDate = (date) => {

    const startDate =moment().format('YYYY-MM-DD');
    const checkDate = moment(date).format('YYYY-MM-DD');
    const endDate = moment().add(dateOffset, 'days').format('YYYY-MM-DD');

    if(checkDate < startDate) return true;
    if(checkDate >= endDate) return true;

    return isDelDate(date);
  }

  // 日期是否畫斜線
  const isDelDate = (date) => {

    // let res = false;
    // const todayFormatted = moment().format('YYYY-MM-DD')

    const startDate =moment().format('YYYY-MM-DD');
    const checkDate = moment(date).format('YYYY-MM-DD');
    const endDate = moment().add(dateOffset, 'days').format('YYYY-MM-DD');
    // console.log('end',endDate)
    if(checkDate < startDate) return false;
    if(checkDate >= endDate) return false;

    // if(bookings && timeOptions.length && typeof bookings[checkDate] !== 'undefined'){
    //   // console.log("works",bookings[dateFormatted])
    //   for( const timeOpt of timeOptions){
    //     const bookedCount = bookings[checkDate][timeOpt]
    //     if(maxBookingEachPeriod > bookedCount) return false;
    //   }
    // }


    return getAvailableTimeOptions(date).length <= 0
  }

  //本日可用時段
  const getAvailableTimeOptions = (date) => {

    const today = moment().format('YYYY-MM-DD')
    const nowTimeFixer = moment().add(hourOffset,'hours').format('H:mm')
    const checkDate = moment(date).format('YYYY-MM-DD')

    if(!maxBookingEachPeriod) return timeOptions;

    const Opts = timeOptions

    const available = [];

    if(bookings && Opts.length && typeof bookings[checkDate] !== 'undefined'){
      // console.log("works",bookings[dateFormatted])
      for( const timeOpt of Opts){
        //已訂數量
        const bookedCount = bookings[checkDate][timeOpt]
        //今天且超過 {hourOffset}
        if(today === checkDate && nowTimeFixer > timeOpt){
          continue;
        }
        if(maxBookingEachPeriod > bookedCount) available.push(timeOpt);
      }
    }

    return available;
  }


  useEffect(()=>{
    axios.get(`${apiBase}/api/guestBooking`).then(
      (response) => {
        console.log(response.data.bookings)
        console.log(response.data)
        setTimeOptions(response.data.timePeriod)
        setMaxGuest(response.data.maxGuest)
        setBookings(response.data.bookings)
        setMaxBookingEachPeriod(response.data.maxBookingEachPeriod)
        setLoadingCalendar(false)
      },
      (error) => {
        console.log(error)
      }
    )
  },[])

  // 時間選項
  useEffect(()=>{

    const available = getAvailableTimeOptions(bookingDate)

    setAvailableTimeOptions(available)

    if( available.length ) setBookingTime(available[0])
    if( !available.length ) setBookingTime(null)

    // if(!bookingTime ) setBookingTime(available[0])

    // if(!bookingTime || !available.includes(bookingTime) ) setBookingTime(available[0])


  },[maxBookingEachPeriod,bookingDate,timeOptions])

  // useEffect(()=>{
  //   if( availableTimeOptions.length ) setBookingTime(availableTimeOptions[0])
  // },[availableTimeOptions])

  //人數選項
  useEffect(()=>{
    const GuestOptions = Array(maxGuest).fill(null).map((content,idx)=>{
      return idx + 1;
    })
    setGuestOptions(GuestOptions)
  },[maxGuest])

  return (
    <Layout>
      <div className="wrap px-6 mx-auto py-8 md:px-10 md:py-36 min-h-full-content md:flex">
        <div className="md:w-1/4">
          <h2 className="uppercase font-light text-gray-33 text-2xl md:text-4xl">ONLINE BOOKING</h2>
        </div>
        <div
          className={`mt-6 md:mb-0 px-3 md:w-1/2 md:px-20 md:pt-12 transition transition-all duration-500  ${bookingStatus === 0 ? 'block' : null} ${bookingStatus === 1 ? 'hidden md:block' : null} ${bookingStatus === 2 ? 'hidden md:block pointer-events-none opacity-60' : null}`}
        >
          {
            loadingCalendar ?
              "Loading..."
              :
              <>
                <Calendar
                  locale="zh-TW"
                  calendarType="US"
                  value={bookingDate}
                  formatShortWeekday={shortWeekdayFormatter}
                  formatMonthYear={monthYearFomatter}
                  prevLabel={<BsChevronCompactLeft className='mx-auto'/>}
                  nextLabel={<BsChevronCompactRight className='mx-auto'/>}
                  prev2Label={null}
                  next2Label={null}
                  maxDetail="month"
                  defaultView="month"
                  minDetail="month"
                  minDate={setStartDate()}
                  maxDate={setMaxDate()}
                  tileDisabled = {({activeStartDate, date, view })=>{

                    return isDisabledDate(date)

                  }}

                  onClickDay ={(value)=>{
                    setBookingDate(value)
                  }}

                  tileClassName = {({ date, view }) => {
                    if(isDelDate(date)){
                      return "with-del-line"
                    }
                    return null
                  }}

                />
                {
                  availableTimeOptions.length ?
                    <div className="text-center">
                      <Button
                        onClick={()=>{ setBookingStatus(1)}}
                        Padding="py-2"
                        textSize="base"
                        className={`mt-6 px-3 tracking-wider text-base cursor-pointer transition-opacity duration-300 md:hidden ${bookingTime && bookingDate && bookingGuest ? null : 'opacity-50 pointer-events-none'}`}
                      >
                        下一步
                      </Button>
                    </div> :
                    <div className="border border-gray-99 text-red-500 rounded-md p-3 mt-3" dangerouslySetInnerHTML={{__html:PleaseSelectAnotherDay}} />
                }

              </>
          }
        </div>
        <div className="pt-10 md:w-1/4 md:max-w-250 md:pt-12" >
          <h3 className={`text-lg text-gray-33 ${bookingStatus === 0 ? 'hidden md:block' : null}`}>
            預約賞屋
          </h3>

          <div className={`${bookingStatus === 0 ? 'hidden md:block' : null} ${bookingStatus === 1 ? 'block' : null} ${bookingStatus === 2 ? 'hidden' : null}`}>
            <div className="mt-10 mb-5 border-t border-b border-gray-99 text-gray-4d md:mb-0">
              <div className='flex justify-between py-4 md:py-2 border-b border-gray-99'>
                <div>Date</div><div>{moment(bookingDate).format('YYYY/MM/DD')}</div>
              </div>
              <div
                className='flex justify-between py-4 md:py-2 border-b border-gray-99 relative cursor-pointer'
                onClick={()=>{
                  setTimeOptionsOpen(!timeOptionsOpen);
                }}
                ref={timeSelector}
              >

                <div>Time</div>
                <div className="flex items-center">
                  {
                    availableTimeOptions.length ?
                      <>
                        {bookingTime}
                        {
                          availableTimeOptions.length > 1 ?
                            timeOptionsOpen ? <BsDash /> : <BsPlus/>  : null

                        }
                      </>
                      :
                      <span className="text-gray-99 pl-3" dangerouslySetInnerHTML={{__html:PleaseSelectAnotherDay}} />
                  }
                </div>
                {
                  timeOptionsOpen && availableTimeOptions.length ?
                    <OptionsRenderer
                      value={bookingTime}
                      options={availableTimeOptions}
                      onOptionClick = {
                        (opt)=>{
                          setBookingTime(opt)
                        }
                      }
                    /> :
                    null
                }
              </div>
              <div
                className='flex justify-between py-4 md:py-2 relative cursor-pointer'
                ref={guestSelector}
                onClick={()=>{
                  setGuestOptionOpen(!guestOptionOpen)
                }}
              >
                <div>Guest</div>
                <div className="flex items-center">
                  {bookingGuest} { guestOptionOpen ? <BsDash /> : <BsPlus/> }
                </div>
                {
                  guestOptionOpen ?
                    <OptionsRenderer
                      value={bookingGuest}
                      options={GuestOptions}
                      onOptionClick={
                        (opt)=>{
                          setBookingGuest(opt)
                        }
                      }
                    /> :
                    null
                }
              </div>
            </div>
            <div className="text-center">
              <Button
                onClick={()=>{ setBookingStatus(0)}}
                Padding="py-2"
                textSize="base"
                className={`mt-6 px-3 tracking-wider text-base cursor-pointer transition-opacity duration-300 mr-4 md:hidden ${bookingTime && bookingDate && bookingGuest ? null : 'opacity-50 pointer-events-none'}`}
              >
                上一步
              </Button>
              <Button
                onClick={()=>{ setBookingStatus(2)}}
                Padding="py-2"
                textSize="base"
                className={`mt-6 px-3 tracking-wider text-base cursor-pointer transition-opacity duration-300 ${bookingTime && bookingDate && bookingGuest ? null : 'opacity-50 pointer-events-none'}`}
              >
                下一步
              </Button>
            </div>
          </div>

          <form
            onSubmit={(e)=>{
              e.preventDefault()
              addBooking()
            }}
            autoComplete='off'
            className={`mt-10 md:mt-0 ${bookingStatus === 0 || bookingStatus === 1 ? "hidden" : null} ${bookingStatus === 2 ? "block" : null}`}
          >
            <div className="text-gray-4d md:mt-10 grid grid-cols-1 gap-4 border-t border-gray-99 md:border-0 pt-4 md:pt-0">
              <div className="relative">
                <label className="absolute left-0 top-0 h-full w-auto flex items-start">
                  姓名
                </label>
                <input
                  value={name}
                  onChange={(e)=>{
                    const val = e.target.value
                    setName(val)
                  }}
                  name="name"
                  type="text"
                  className="text-right border-b border-gray-99 outline-none pb-5 w-full md:pb-3 md:focus:pb-4 transition-all duration-300"
                  required
                  autoComplete='off'
                />
              </div>
              <div className="relative">
                <label className="absolute left-0 top-0 h-full w-auto flex items-start">
                  電話
                </label>
                <input
                  value={phone}
                  onChange={(e)=>{
                    const val = e.target.value
                    setPhone(val)
                  }}
                  name="phone"
                  type="text"
                  className="text-right border-b border-gray-99 outline-none pb-5 w-full md:pb-3 md:focus:pb-4 transition-all duration-300"
                  required
                  autoComplete='off'
                />
              </div>
              <div className="relative">
                <label className="absolute left-0 top-0 h-full w-auto flex items-start">
                  E-mail
                </label>
                <input
                  value={email}
                  onChange={(e)=>{
                    const val = e.target.value
                    setEmail(val)
                  }}
                  name="email"
                  type="email"
                  className="text-right border-b border-gray-99 outline-none pb-5 w-full md:pb-3 md:focus:pb-4 transition-all duration-300"
                  required
                  autoComplete='off'
                />
              </div>
            </div>
            <div className="text-center mt-5">
              <button ref={submitRef} type="submit" className="hidden">Submit</button>
              <Button onClick={()=>{setBookingStatus(1)}} Padding="py-2" textSize="base" className="mt-6 px-3 tracking-wider text-base cursor-pointer">上一步</Button>
              <Button
                onClick={()=>{
                  if(!submitRef.current) return false;
                  submitRef.current.click()
                }}
                Padding="py-2"
                textSize="base"
                className="mt-6 px-3 tracking-wider text-base cursor-pointer ml-4"
              >
                確認
              </Button>
            </div>
          </form>

        </div>
      </div>
    </Layout>
  )
}